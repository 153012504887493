
import Api from "@/lib/api";
import Vue from "vue";
import VForm from "@/lib/types/v-form";
import { Rq, Table } from "@/lib/amdt";
import WorgError from "@/lib/worg-error";
import IUser from "@/lib/interfaces/user";
import {
  required,
  maxLength,
  minLength,
  passwordMatch,
  email,
} from "@/lib/validations";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ATextFieldPassword from "@/components/elements/a-text-field-password.vue";

export default Vue.extend({
  name: "MyAccount",

  components: { vAlertMessage, ABtn, ATextField, ATextFieldPassword },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      user: {
        id: "",
        usuario: "",
        email: "",
        senha: "",
        dispositivo: "0",
        data_prox_expiracao_senha: "",
        dias_expiracao_senha: "0",
        desativado: "0",
        data_criacao: "",
      } as IUser,

      senhaConfirmacao: "",
    };
  },

  watch: {
    user: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
      },
    },

    storeUser() {
      this.clearForm();
    },
  },

  computed: {
    cValidaSenha(): Array<(value: string) => string | boolean> {
      return this.user.senha ? [minLength(8), maxLength(40)] : [];
    },

    cValidaSenhaConfirmacao(): Array<(value: string) => string | boolean> {
      return this.user.senha
        ? [required, passwordMatch(this.user.senha, true)]
        : [passwordMatch(this.user.senha)];
    },

    cValidaEmail(): Array<(value: string) => string | boolean> {
      return this.user.email ? [maxLength(40), email] : [];
    },

    form(): VForm {
      return this.$refs.form as VForm;
    },

    storeUser(): IUser {
      return this.$store.state.user;
    },
  },

  mounted() {
    this.getUserById().then();
  },

  methods: {
    required,
    minLength,
    maxLength,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("user.write");

        const tblUser = new Table("usuario");

        const obj = { ...this.user };
        tblUser.setColsFromObject(obj);
        tblUser.addRowsFromObject(obj);

        rq.addTable(tblUser);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          if (WorgError.getErrorByCode(this.ret_id).name === "VldError")
            this.form.validate();

          return;
        } else {
          this.ret_msg = "Usuário atualizado com sucesso";
          this.$store.commit("updateUser", this.user);
        }

        this.clearForm();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getUserById() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("user.read", {
          id: this.storeUser.id,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        const users = rsp.getTable("usuario")?.getRowsObject() ?? [];
        if (users.length > 0) {
          this.$store.commit("updateUser", { ...users[0] });
        } else {
          this.ret_id = -1;
          this.ret_msg = "Usuário não encontrado";
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.user = { ...this.storeUser, senha: "" };
      this.senhaConfirmacao = "";

      this.form.resetValidation();
    },
  },
});
